import React, { useState, useEffect, useContext } from "react";
import useApiAxios from "../../api/useApiAxios";
import { Link, useParams } from "react-router-dom";
import { Context } from "../../context/Context";
import { useSearchParams } from "react-router-dom";

const Home = ({ fetchMarketData }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const id = searchParams.get("id");
  const { setMarketData, marketData } = useContext(Context);

  const defaultHeaderColor = "#3498db";
  const defaultFooterColor = "#e74c3c";

  const headerColor = marketData?.headerColor || defaultHeaderColor;
  const footerColor = marketData?.footerColor || defaultFooterColor;


  const staticImagePath = process.env.REACT_APP_IMG_URL;

  if (!marketData || !marketData.bannerImage) {
    return null; // or render a loading indicator
  }

  const bannerImageUrl = `${staticImagePath}/${marketData.bannerImage}`;
  console.log(bannerImageUrl);
  const backgroundImage = {
    backgroundImage: `url(${bannerImageUrl})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };

  return (
    <>
      <section className="slider-area" style={backgroundImage}>
        <div className="slider-active">
          <div className="single-slider slider-height">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-12">
                  <div className="hero__caption text-center">
                    <h1>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: marketData?.title,
                        }}
                      />
                    </h1>
                    {marketData ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: marketData?.description,
                        }}
                      />
                    ) : (
                      "Market Description"
                    )}
                    
                    <Link
                      to={`/apply-here?id=${marketData?.market}`}
                      className="btn hero-btn"
                      style={{
                        backgroundColor: headerColor,
                        cursor: "pointer",
                        transition: "background-color 0.3s ease",
                        overflow: "hidden",
                        position: "relative",
                        transition: "background-color 0.3s ease",

                      }}
                      onMouseOver={(e) =>
                        (e.currentTarget.style.backgroundColor = footerColor)
                      }
                      onMouseOut={(e) =>
                        (e.currentTarget.style.backgroundColor = headerColor)
                      }
                    >
                      <style>
                        {`
                          .btn::before {
                            content: "";
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: 101%;
                            height: 101%;
                            background: ${footerColor};
                            z-index: 1;
                            border-radius: 5px;
                            transition: transform 0.5s;
                            transform-origin: 0 0;
                            transform: scaleX(0);
                            border-radius: 0px;
                          }
                          .btn:hover::before {
                            transform: scaleX(1);
                          }
                        `}
                      </style>
                      Get Started Now
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {marketData?.section.map((item, index) => (
        <section
          key={index}
          className="about-area2 section-padding40 section-bg2 py-5"
        >
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-7 col-lg-7 col-md-12">
                <div className="about-caption mb-50">
                  {/* Section Tittle */}
                  <div className="section-tittle mb-25">
                    <h2>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item?.title,
                        }}
                      />
                    </h2>
                  </div>
                  <div className="mb-20">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item?.description,
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-xl-5 col-lg-5 col-md-9 col-sm-9">
                {/* about-img */}
                <div className="about-img">
                  <img
                    src={`${staticImagePath}/${item ? item.image : ""}`}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      ))}
      <section className="about-area2 pb-bottom">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-5 col-lg-5 col-md-9">
              {/* about-img */}
              <div className="about-img">
                <img
                  src={`${staticImagePath}/${
                    marketData ? marketData.section2Image : ""
                  }`}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
