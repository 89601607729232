import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useExtractColors } from "react-extract-colors";

// Function to calculate luminance from a hex color
const calculateLuminance = (hex) => {
  if (hex.startsWith("#")) hex = hex.slice(1); // Remove "#" if present

  // Convert hex to RGB
  const r = parseInt(hex.substring(0, 2), 16) / 255;
  const g = parseInt(hex.substring(2, 4), 16) / 255;
  const b = parseInt(hex.substring(4, 6), 16) / 255;

  // Apply luminance formula
  const a = [r, g, b].map((color) => {
    return color <= 0.03928
      ? color / 12.92
      : Math.pow((color + 0.055) / 1.055, 2.4);
  });

  // Calculate luminance using the formula
  return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
};

const Header = ({ marketData }) => {
  const staticImagePath = process.env.REACT_APP_IMG_URL;
  const loginPageUrl = process.env.REACT_APP_LOGIN_PAGE_URL;

  const [isScrolled, setIsScrolled] = useState(false);
  console.warn("market content data", marketData);

  // Background image URL
  const backgroundImageUrl = marketData?.bannerImage
    ? `${staticImagePath}/${marketData.bannerImage}`
    : "";

  // Use the hook to extract colors from the background image
  const { colors, loading, error } = useExtractColors(backgroundImageUrl);

  // Default header color (this will not change)
  const headerColor = marketData?.headerColor || "#ffffff";

  // Extract the first color from the image or fallback to headerColor
  const extractedHeaderColor = colors?.[0] || headerColor;

  // Calculate luminance of the extracted color
  const luminance = calculateLuminance(extractedHeaderColor);

  // Determine the text color based on luminance (dark or light)
  const HeaderTextColor = luminance > 0.5 ? "black" : "white"; 

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50); // Change threshold as needed
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const headerStyle = {
    backgroundColor: isScrolled ? headerColor : "transparent",
    transition: "background-color 0.3s ease" // Smooth transition effect
  };

  return (
    <div>
      <header>
        {/* Header Start */}
        <div className="header-area header-transparent">
          <div className="main-header">
            <div className="header-bottom header-sticky" style={headerStyle}>
              <div className="container-fluid">
                <div className="row align-items-center">
                  {/* Logo */}
                  <div className="col-xl-12 col-lg-12">
                    <div className="logo d-flex align-items-center justify-content-center py-2">

                      <div
                        dangerouslySetInnerHTML={{
                          __html: marketData?.mainTitle,
                        }}
                        className="mt-4"
                      />
                    </div>
                  </div>
                </div>
                <div className="row align-items-center">
                  <div className="col-xl-12 col-lg-12">
                    <div className="menu-wrapper d-flex align-items-center justify-content-center">
                      {/* Main-menu */}
                      <div className="main-menu d-none d-lg-block">
                        <nav>
                          <ul id="navigation">
                            <li className="active">
                              <Link to={`/?id=${marketData?.market}`}>
                                Home
                              </Link>
                            </li>
                            <li>
                              <Link to={`/meetourvendor?id=${marketData?.market}`}>
                                Meet our Vendors
                              </Link>
                            </li>
                            <li>
                              <Link to={`/marketmap?id=${marketData?.market}`}>
                                Market Maps
                              </Link>
                            </li>
                            <li>
                              <Link to={`/apply-here?id=${marketData?.market}`}>
                                Apply Here
                              </Link>
                            </li>
                            <li>
                              <Link to={`/marketcontent`}>Market Content</Link>
                            </li>
                          </ul>
                        </nav>
                      </div>
                      {/* Header-btn */}
                      <div className="header-right-btn d-none d-lg-block ml-65">
                        <Link
                          to={loginPageUrl}
                          className="border-btn"
                          target="_blank" // Opens in a new tab or window
                          rel="noopener noreferrer" // Security measure when using target="_blank"
                        >
                          Login
                        </Link>
                      </div>
                    </div>
                  </div>
                  {/* Mobile Menu */}
                  <div className="col-12">
                    <div className="mobile_menu d-block d-lg-none" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Header End */}
      </header>
    </div>
  );
};

export default Header;
